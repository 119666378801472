<template>
    <div class="pt-5 pt-lg-0">
        <div v-show="currentUser.is_superuser || currentUser.is_ventura_admin" class="container" v-if="loading">
            <div class="fl-eq mt-4">
                <loading-animation/>
            </div>
        </div>

        <div v-show="currentUser.is_superuser || currentUser.is_ventura_admin" class="container" v-else-if="error">
            <div class="card bg-danger fl-te-c">
                <div>
                    <error-icon/>
                    Failed to load data from the server.
                </div>
                <div>
                    <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                </div>
            </div>
        </div>

        <inner-page-card v-if="!loading" design="basic-b" class="bs-3" :link-back="true" heading="Transaction Details">
            <div v-if="currentUser.is_superuser || currentUser.is_ventura_admin" class="row">
                <div class="col-12">
                    <section>
                        <div class="p-4">
                            <div class="row">
                                <div v-if="details.trn_id" class="col-lg-3 col-6">
                                    <h6 class="font-inter-medium ">Trn Id </h6>
                                    <p class="">{{ details.trn_id }}</p>
                                </div>
                                <div v-if="details.membership_type" class="col-lg-3 col-6 mt-lg-0 mt-2">
                                    <h6 class="font-inter-medium ">Membership Type </h6>
                                    <p class="">{{ details.membership_type }}</p>
                                </div>
                                <div v-if="details.amount" class="col-lg-3 col-6">
                                    <h6 class="font-inter-medium ">Amount</h6>
                                    <p class="">{{ details.amount }}</p>
                                </div>
                                <div class="col-lg-3 col-6 mt-lg-0 mt-2">
                                    <h6 class="font-inter-medium ">Currency </h6>
                                    <p class="">INR</p>
                                </div>
                                <div v-if="details.user_name" class="col-lg-3 col-6 mt-lg-2 mt-2">
                                    <h6 class="font-inter-medium ">User </h6>
                                    <p class="">{{ details.user_name }}</p>
                                </div>
                                <div v-if="details.status_code" class="col-lg-3 col-6">
                                    <h6 class="font-inter-medium ">Status Code </h6>
                                    <p class="">{{ details.status_code }}</p>
                                </div>
                                <div v-if="details.payment_status" class="col-lg-3 col-6">
                                    <h6 class="font-inter-medium ">Payment Status </h6>
                                    <p class="">{{ details.payment_status }}</p>
                                </div>
                                <div v-if="details.bank_selection_code" class="col-lg-3 col-6">
                                    <h6 class="font-inter-medium ">Bank Code </h6>
                                    <p class="">{{ details.bank_selection_code }}</p>
                                </div>
                                <div v-if="details.created_date" class="col-lg-3 col-6 mt-lg-3 mt-2">
                                    <h6 class="font-inter-medium ">Transaction Initiated Date</h6>
                                    <p class="">{{ details.created_date }}</p>
                                </div>
                                <div v-if="details.date_time" class="col-lg-3 col-6 mt-lg-0 mt-2">
                                    <h6 class="font-inter-medium ">Transaction Date</h6>
                                    <p class="">{{ details.date_time }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
            <div v-else class="text-center py-7">
                <h4 class="font-inter-bold">Permission Denied!</h4>
                <p class="">Sorry, You have no permission to view this page.</p>
            </div>
        </inner-page-card>

        <modal class="c-modal-1 header-none bg-layer no-close" :no-close-on-backdrop="true" title=""
               ref="failedModal" width="30r"
               header-color="">
            <div class="px-lg-4 py-4 pt-6">
                <div class="fl-x fl-j-c">
                    <img
                        src="../../assets/web/emd-submissioon/not-submitted.png"
                        class="error-icon" alt="">
                </div>
                <p class="text-center fs-6 font-inter-bold mt-4">Request Failed !</p>
                <p class="text-center">{{ responseData.message }}</p>
                <btn text="Close" @click="$refs.failedModal.close()"
                     class="c-secondary-bg fs-lg-2 c-secondary-border mb-3"
                     size="lg" block></btn>
            </div>
        </modal>

        <modal class="c-modal-1 header-none bg-layer no-close" :no-close-on-backdrop="true" title=""
               ref="successModal" width="30r"
               header-color="">
            <div class="px-lg-4 py-4 pt-6">
                <div class="fl-x fl-j-c">
                    <img src="../../assets/web/emd-submissioon/submitted.png"
                         class="error-icon" alt="">
                </div>
                <p class="text-center fs-6 font-inter-bold mt-4">Request Success !</p>
                <p class="text-center">{{ responseData.message }}</p>
                <btn text="Close" @click="$refs.successModal.close()"
                     class="c-secondary-bg fs-lg-2 c-secondary-border mb-3"
                     size="lg" block></btn>
            </div>
        </modal>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'TransactionDetails',

    data () {
        return {
            id          : this.$route.params.id,
            details     : '',
            error       : false,
            loading     : true,
            processData : {
                title   : '',
                type    : '',
                message : ''
            },
            responseData : {
                message : ''
            }
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;
            that.loading = true;
            const model = {
                txn_id : that.id
            };
            axios.form(urls.transactions.detailsUrl, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = { ...json.data };
                    that.loading = false;
                } else {
                    that.loading = false;
                }
            }).catch(function (exception) {
                console.log('Exception at the incident details loading => ', exception);
                that.loading = false;
            });
        }
    }
};
</script>

<style scoped>

.bg-blue-layer {
    background: url("../../assets/web/bg/blue-strip-alternate.jpg");
}

.c-margin-x {
    margin: -15px -15px 0 -15px;
}

.icon-md {
    height: 2.5rem !important;
}

</style>
